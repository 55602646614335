/* Custom scrollbar styling */
.custom-scrollbar::-webkit-scrollbar {
    width: 13px; /* Increase width for better visibility */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgb(150, 150, 150); /* Thumb color */
    border-radius: 6px; /* Rounded corners for the thumb */
    border: 2px solid white; /* Adds a border to the thumb for better visibility */
}

.custom-scrollbar::-webkit-scrollbar-track {
    background-color: transparent; /* Ensures the track has no interfering background */
}

/* Styling the scrollbar buttons with visible arrows */
.custom-scrollbar::-webkit-scrollbar-button {
    height: 20px; /* Ensuring sufficient height for visibility */
    background-color: #38b2ac; /* Teal color for the button */
    position: relative; /* Needed for positioning the arrow */
}

/* Customize the scrollbar buttons */
.custom-scrollbar::-webkit-scrollbar-button {
    background-color: #319795;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%); /* Creates a triangle shape */
    border: none; /* Remove any default border */
}

/* Customize the scrollbar track */
.custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Customize the scrollbar thumb */
.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Customize the scrollbar corner */
.custom-scrollbar::-webkit-scrollbar-corner {
    background: #f1f1f1;
}

